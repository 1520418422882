import React, { lazy, useEffect } from 'react';
import styled from 'styled-components';
import { formatTime } from 'utils/format-time';
import Image from 'react-image';
import { css } from '@styled-system/css';
import { Box } from 'components/styled/box';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import VideoJs from './player/VideoJs';
import Share from '../audio/share';
import PremiumOverlay from './PremiumOverlay';
import { Placeholder } from 'components/PlaceholderImage';
import AddToFavourite from 'components/favourite/AddToFavourite';
import { FaVideo } from 'react-icons/fa';
import { subscribeHandler } from 'utils/payment.utils';
import { WatchButton } from 'components/WatchButton';
import playIcon from 'assets/images/polygon.png';
import { API } from 'utils/api.config';
import { callbackTypeTwo } from 'utils/gp-callbacks';
const CountryRestrict = lazy(() => import('./CountryRestrict'));
const TrailerPlayer = lazy(() => import('components/TrailerPlayer'));

export default function WatchNowCard({
  imageUrl,
  title,
  description,
  genres,
  releaseDate,
  duration,
  directors,
  casts,
  producers,
  onClickPlay,
  // onCreateNewListClick,
  // onAddToListClick,
  // wishlist,
  id,
  currentTrack,
  showPlayer,
  series,
  onClose,
  share,
  setEpisodeIndex,
  episodeIndex,
  setSeriesIndex,
  seriesLength,
  seriesIndex,
  favourite,
  subtitle,
  trialPath,
  hasTvodSub,
  from,
  isGpSubscribed,
  watchTime,
  setWatchTime
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userProfiles } = useSelector((state) => state.userSub);
  const { currentUser } = useSelector((state) => state.user);
  const { theme } = useSelector((state) => state.theme);
  const [trailerEnd, setTrailerEnd] = React.useState(false);
  const [trailerModal, setTrailerModal] = React.useState(false);
  const [videoDuration, setVideoDuration] = React.useState('');
  const platormTitle = window.platform_info.title;
  const gpUser = JSON.parse(localStorage.getItem(`gp_user_${platormTitle}`));
  const robiUser = JSON.parse(localStorage.getItem(`robi_user_${platormTitle}`));
  const asthaUser = JSON.parse(localStorage.getItem(`astha_user_${platormTitle}`));
  const playerRef = React.useRef(null);
  const resumePlayRef = React.useRef(null);
  const couponUser = localStorage.getItem(`coupon_login_${title}`);
  const location = useLocation();

  React.useEffect(() => {
    // get latest episodes of a series
    if (currentTrack?.type === 'episode' && currentUser && !couponUser && !gpUser && !robiUser) {
      API.get(`track/api/v1/watchTime/latest?content_id=${id}&type=series`)
        .then((res) => {
          console.log(res?.data);
          if (res?.data.isWatched) {
            // if(from != 'slug') {
            //   console.log('hiting error on latest episode');
            //   history.push(`/series/${id}?season_index=${res?.data.season_id}&episode_index=${res?.data.episode_id}`)
            // } else {
            console.log('Latest pushed to change');
            setSeriesIndex(res?.data?.season_id - 1);
            setEpisodeIndex(res?.data?.episode_id - 1);
            // }
          }
        })
        .catch((err) => {
          console.error('Error getting latest content', err);
        });
    }
  }, [currentUser]);

  React.useEffect(() => {
    let getPath;
    const searchParams = new URLSearchParams(location.search);
    const episodeId = searchParams.get('episode_index');
    const seasonId = searchParams.get('season_index');

    // Get video and episode resuming time
    if (currentTrack?.type === 'video') {
      getPath = `track/api/v1/watchTime?content_id=${currentTrack?.id}&type=${currentTrack?.type}`;
    } else if (currentTrack?.type === 'episode') {
      if (from != 'slug') {
        getPath = `track/api/v1/watchTime?content_id=${id}&season_id=${seasonId}&episode_id=${episodeId}&type=series`;
      } else {
        getPath = `track/api/v1/watchTime?content_id=${id}&season_id=${
          seriesIndex + 1
        }&episode_id=${episodeIndex + 1}&type=series`;
      }
    }

    if (currentUser && !couponUser && !gpUser && !asthaUser && !robiUser) {
      API.get(getPath)
        .then((res) => {
          console.log('Successful Get request', res);
          // watchTimeRef.current = res?.data.time;
          setWatchTime(res?.data.time);
        })
        .catch((err) => {
          console.error('Error', err);
        });
    }
  }, [location.search, seriesIndex, episodeIndex, currentUser]);

  console.log('Current Watchtime', watchTime);

  const handlePlayClick = () => {
    if (gpUser) {
      callbackTypeTwo(window.location.href, currentTrack?.premium);
    } else if (currentTrack?.premium && !currentUser) {
      history.push('/login');
    } else if (currentTrack?.premium && !userProfiles?.subscribe) {
      subscribeHandler();
    } else {
      onClickPlay();
    }
  };

  const playerInstanceReady = (player) => {
    playerRef.current = player;
  };

  const handleResumePlay = () => {
    if (playerRef.current && watchTime) {
      let player = playerRef.current;
      player.currentTime(watchTime);
      player.play();
      // resumePlayRef.current.style.display = 'none';
    }
    // else if (playerRef.current && !watchTime) {
    //   playerRef.current.play();
    // }
    else {
      console.log('Player is not ready yet, try later');
    }
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      handleResumePlay();
    }, 2000);

    return () => clearTimeout(timeOutId);
  }, [playerRef.current, watchTime]);

  const shouldVideoPlay = () => {
    if (!currentTrack?.premium) {
      return true;
    } else if (currentTrack?.premium && userProfiles?.subscribe) {
      return true;
    } else if (currentTrack?.premium && !userProfiles?.subscribe && !trailerEnd) {
      return true;
    }
    return false;
  };

  // Restrict "Nil Joler Kabbo" content for myGp User
  if (gpUser && id === '6787') {
    return (
      <CountryRestrict
        id="5929"
        theme={theme}
        writeup="This content is only available for ios and android application"
      />
    );
  }

  // Restrict id specific content for our platform
  if (
    id === '5929' ||
    id === '1016' ||
    id === '6611' ||
    id == '7055' ||
    currentTrack?.slug === 'hawa' ||
    currentTrack?.slug === 'hawa-top10'
  ) {
    return (
      <CountryRestrict
        id="5929"
        theme={theme}
        writeup="This content is only available for ios and android application"
      />
    );
  }

  return (
    <Card>
      <Box className="img-container">
        {currentTrack?.restrictedCountry ? (
          <CountryRestrict theme={theme} />
        ) : currentTrack?.premium && !userProfiles?.subscribe && trailerEnd ? (
          <Image
            src={imageUrl}
            alt={title}
            className="banner-img"
            loader={<Placeholder className="banner-img" type="video" />}
            unloader={<Placeholder className="banner-img" type="video" />}
          />
        ) : currentTrack?.premium && !userProfiles?.subscribe && !currentTrack?.trailer ? (
          <Image
            src={imageUrl}
            alt={title}
            className="banner-img"
            loader={<Placeholder className="banner-img" type="video" />}
            unloader={<Placeholder className="banner-img" type="video" />}
          />
        ) : (
          <div className="video-wrapper">
            {shouldVideoPlay() && (
              <VideoJs
                currentTrack={currentTrack}
                userProfiles={userProfiles}
                currentUser={currentUser}
                videoUrl={
                  userProfiles?.subscribe || asthaUser || (gpUser && isGpSubscribed === 'OK')
                    ? currentTrack?.path
                    : currentTrack?.tvod
                    ? hasTvodSub
                      ? currentTrack?.path
                      : currentTrack?.trailerPath
                    : (!currentTrack?.premium && !currentUser) ||
                      (!currentTrack?.premium && currentUser)
                    ? currentTrack?.path
                    : currentTrack?.trailerPath
                }
                onClose={onClose}
                setEpisodeIndex={setEpisodeIndex}
                setSeriesIndex={setSeriesIndex}
                series={series}
                episodeIndex={episodeIndex}
                seriesLength={seriesLength}
                seriesIndex={seriesIndex}
                subtitle={subtitle}
                id={id}
                setTrailerEnd={setTrailerEnd}
                trailerModal={trailerModal}
                setVideoDuration={setVideoDuration}
                from={from}
                playerInstanceReady={playerInstanceReady}
                isGpSubscribed={isGpSubscribed}
              />
            )}
          </div>
        )}

        {(!currentTrack?.tvod && currentTrack?.premium && !userProfiles?.subscribe && trailerEnd) ||
        (!currentTrack?.tvod &&
          currentTrack?.premium &&
          !userProfiles?.subscribe &&
          !currentTrack?.trailer) ? (
          <PremiumOverlay handlePlayClick={handlePlayClick} />
        ) : null}

        {/* Fix needed for Tvod content
        {((currentTrack?.premium && !userProfiles?.subscribe && trailerEnd) ||
          (currentTrack?.premium && !userProfiles?.subscribe && !currentTrack?.trailer) ||
          (currentTrack?.premium && !userProfiles?.subscribe && trailerEnd && !userProfiles?.isTVOD)) ? (
          <BackgroundOverlay />
        ) : null} 
        */}

        {(currentTrack?.premium && !userProfiles?.subscribe && trailerEnd) ||
        (currentTrack?.premium && !userProfiles?.subscribe && !currentTrack?.trailer) ? (
          <BackgroundOverlay />
        ) : null}
      </Box>
      <MetaBox>
        <MetaHeading>
          <Box>
            <Title>
              {title}{' '}
              {gpUser && isGpSubscribed === 'OK'
                ? ''
                : currentTrack?.premium &&
                  !userProfiles?.subscribe &&
                  currentTrack?.trailer &&
                  !currentTrack?.tvod
                ? '(Trailer)'
                : ''}
            </Title>
            <Box display="flex" alignItems="center" className="metaitem-container">
              {/* <MetaItem theme={theme}>
                {releaseDate && new Date(releaseDate).getFullYear()}
              </MetaItem> */}
              {/* <Divider /> */}
              {/* {videoDuration && <Divider theme={theme} />} */}
              <MetaItem theme={theme}>{videoDuration && formatTime(videoDuration)}</MetaItem>
              {episodeIndex !== undefined && (
                <>
                  <Divider theme={theme} />
                  <MetaItem theme={theme}>Episode - {episodeIndex + 1}</MetaItem>
                </>
              )}
            </Box>
          </Box>
          <div className="right">
            {/* {watchTime > 0
              ? <WatchButton style={{ fontSize: '11px' }} onClick={handleResumePlay} ref={resumePlayRef}>
                <img src={playIcon} alt="Play Icon" />
                <span>Resume to Play</span>
              </WatchButton>
              : ''
            } */}
            {currentTrack?.trailer && (
              <RightIcon theme={theme}>
                <span style={{ marginTop: -8 }} onClick={() => setTrailerModal(true)}>
                  <FaVideo />
                </span>
                <span style={{ marginTop: -8 }}>Trailer</span>
              </RightIcon>
            )}

            <RightIcon theme={theme}>
              {/* <Dropdown
                menu={
                  <Button variant="transparent" className="wish-btn-container">
                    <span className="wish-btn"></span>
                    <span>Wishlist</span>
                  </Button>
                }>
                <li onClick={() => onCreateNewListClick(id)}>Create New List</li>
                {wishlist?.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => onAddToListClick({ id: item.id, contentId: id })}>
                    {item.title}
                  </li>
                ))}
              </Dropdown> */}

              <div>
                <AddToFavourite id={id} favourite={favourite} />
              </div>
              <span>Favourite</span>
            </RightIcon>
            <RightIcon>
              <Share shareUrl={`https://${window.location.host}/${share}/${id}`} title={title} />
              <span style={{ marginTop: -6 }}>Share</span>
            </RightIcon>
          </div>
        </MetaHeading>
        {genres && (
          <Genres theme={theme}>
            {genres?.map((item, index) => {
              return (
                <h3 className="genres" key={index}>
                  {item.name} {index === genres.length - 1 ? '' : ', '}
                </h3>
              );
            })}
          </Genres>
        )}

        <DescriptionBox>
          <p className="description">{description}</p>
          <div className="script">
            <table>
              <tbody>
                <tr>
                  <td className="title">
                    Script & <br /> Direction by
                  </td>
                  <td> : </td>
                  <td className="tdlist">
                    {directors &&
                      directors.map((director, index) => {
                        if (director.profileActive === true) {
                          return (
                            <div className="tdlist" key={index}>
                              <Link to={`/artist/${director.id}`}>{director.name}</Link>
                              {index !== directors.length - 1 ? ' | ' : null}
                            </div>
                          );
                        } else {
                          return (
                            <div className="tdlist" key={index}>
                              {director.name}
                              {index !== directors.length - 1 ? ' | ' : null}
                            </div>
                          );
                        }
                      })}
                  </td>
                </tr>
                <tr>
                  <td className="title">Casts</td>
                  <td> : </td>

                  <td className="tdlist">
                    {casts &&
                      casts.map((cast, index) => {
                        if (cast.profileActive === true) {
                          return (
                            <div className="tdlist" key={index}>
                              <Link rel="canonical" to={`/artist/${cast.slug}`}>
                                {cast.name}
                              </Link>
                              {index !== casts.length - 1 && ' | '}
                            </div>
                          );
                        } else {
                          return (
                            <div className="tdlist" key={index}>
                              {cast.name}
                              {index !== casts.length - 1 && ' | '}
                            </div>
                          );
                        }
                      })}
                  </td>
                </tr>
                <tr>
                  <td className="title">Producers</td>
                  <td> : </td>
                  <td className="tdlist">
                    {producers &&
                      producers.map((producer, index) => {
                        if (producer.profileActive === true) {
                          return (
                            <div key={index}>
                              <Link rel="canonical" to={`/artist/${producer.id}`}>
                                {producer.name}
                              </Link>
                              {index !== producers.length - 1 && ' | '}
                            </div>
                          );
                        } else {
                          return (
                            <div key={index}>
                              {producer.name}
                              {index !== producers.length - 1 && ' | '}
                            </div>
                          );
                        }
                      })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </DescriptionBox>
      </MetaBox>
      {trailerModal && (
        <TrailerPlayer
          currentTrack={currentTrack}
          onClose={() => {
            setTrailerModal(false);
            window.location.reload(false);
          }}
        />
      )}
    </Card>
  );
}

const Card = styled.div`
  position: relative;

  .img-container {
    width: 100%;
    height: 480px;
    position: relative;

    @media (min-width: 1400px) {
      height: 550px;
    }
    @media (max-width: 768px) {
      height: 350px;
    }
    @media (max-width: 500px) {
      height: 280px;
    }
    .video-wrapper {
      display: block;
      height: 100%;
      width: 100%;
      position: relative;
      /* overflow: hidden; */
    }
    .skipp-button {
      position: absolute;
      bottom: 15%;
      right: 5%;
      background-color: #fff;
      border-radius: 30px;
      height: 20px;
      width: 50px;
      color: #000 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 111;
      cursor: pointer;
    }
    .banner-img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
      filter: blur(4px);
      -webkit-filter: blur(4px);
      border: solid 1px rgba(255, 255, 255, 0.6);
    }

    .play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 999;

      background-color: transparent;
      transform: translate(-50%, -50%);
    }
  }
`;

const MetaHeading = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0rem 1rem;

  .right {
    display: flex;
    align-items: center;
    gap: 25px;
    @media (max-width: 768px) {
      gap: 25px;
    }
    @media (max-width: 445px) {
      gap: 10px;
    }
  }
`;

const RightIcon = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.5px;
  gap: 5px;
  font-size: 11px;
  transition: 0.3s;
  & svg {
    margin-top: 5px;
    font-size: 30px;
    transition: 0.3s ease;
    :hover {
      color: var(--hover-color);
      transition: 0.3s ease;
    }
  }
  @media (max-width: 768px) {
    font-size: 12px;
    & svg {
      font-size: 28px;
    }
  }
  @media (max-width: 445px) {
    font-size: 9px;
    & svg {
      font-size: 25px;
    }
  }
`;
const Genres = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  margin-bottom: 0.8rem;
  flex-wrap: wrap;
  margin-top: 10px;
  @media (max-width: 425px) {
    gap: 0.2rem;
  }
  .genres {
    display: inline-block;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0;
    color: ${(props) => (props.theme === 'dark' ? '#D2D2D2' : '#888')};

    @media (max-width: 425px) {
      font-size: 10px;
    }
  }
`;

const DescriptionBox = styled.div`
  display: grid;
  gap: 0.2rem;
  margin-bottom: 20px;
  @media (max-width: 445px) {
    gap: 0.5rem;
  }
  .description {
    margin-top: 0.5rem;
    max-height: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 445px) {
      font-size: 11px;
    }
  }
  .script {
    /* color: #fff; */
    /* justify-self: right; */
    .tdlist {
      display: flex;
      align-items: 'center';
      gap: 4px;
      flex-wrap: wrap;
    }
    @media (max-width: 1000px) {
      justify-self: left;
    }
    table {
      /* border-spacing: 10px 0px; */
      margin: 0;
      width: 100%;
      @media (max-width: 445px) {
        font-size: 14px;
        border-spacing: 5px 0px;
      }
      tr {
        width: 100%;
        align-items: center;
        display: grid;
        grid-template-columns: 150px 20px 1fr;
        @media (max-width: 500px) {
          grid-template-columns: 100px 10px 1fr;
        }
      }

      a {
        color: currentColor;
        text-decoration: none;
        transition: 0.3s;
        :hover {
          color: #f3005e;
        }
      }
    }
  }
`;

const Title = styled('h1')(
  css({
    fontSize: [16, 24],
    fontWeight: 'bold',
    lineHeight: 1.31,
    color: 'var(--heading-color)',
    textTransform: 'uppercase',
    paddingTop: '.5rem',
    letterSpacing: [0, 1]
  })
);

const MetaBox = styled.div`
  position: relative;
  @media (max-width: 639px) {
    padding: 0 1rem;
  }
`;

const MetaItem = styled.h2`
  font-size: 18px;
  color: ${(props) => (props.theme === 'light' ? 'var(--silver)' : '#dbdbdb')};
  font-weight: 400;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 445px) {
    font-size: 10px;
  }
  margin-bottom: 0;
`;
const Divider = styled.span`
  width: 1px;
  height: 14px;
  border: solid 1px
    ${(props) => (props.theme === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)')};
  margin-left: 4px;
  margin-right: 4px;
  @media (max-width: 425px) {
    height: 9px;
  }
`;

const BackgroundOverlay = styled.div`
  width: 100%;
  height: 100%;
  /* background:linear-gradient(to right,var(--dark-navy-blue) 15%,rgba(0,15,30,0) 100%); */
  background: rgba(0, 15, 30, 0.6);
  position: absolute;
  top: 0;
  left: 0;
`;
